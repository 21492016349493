import React                from "react"
import RawHtml              from 'entrada-ui/RawHtml'
import Img                    from "gatsby-image"
import {
    Col,
    Row,
    Container
}                             from 'react-bootstrap'
import cx                     from 'utils/classnames'
import Link                   from 'components/Link'
import Seo                    from 'components/Seo'
// import styles                 from './styles.module.scss'

const PageTemplate = ({ pageContext: { data } }) => {
  const {
    siteMetadata,
    content,
    slug,
    title,
    wordpress_id,
    yoast_meta
  } = data

  return (
    <>
      <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
      <Container>
        <Row>
          <Col>
            <h1>{title}</h1>
            <RawHtml html={content} />
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default PageTemplate

